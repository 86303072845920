<template>
    <div>
<SubHeader :link1="{ name: 'User'}" :link1Name="$t('Users')" :linkActiveName="$t('Add')" :linkB1="{ name: 'User'}" :linkB1Name="$t('Back')" />
<DoneAlert :msg="$t('Data has been updated')" :visible="ShowDoneAlert" />
 <b-container  class=" mt-5" >
     
<b-card bg-variant="light" :header="$t('Add')+ ' ' + $t('User') " class="text-left">
<br>   
<b-form @submit="onSubmit"  v-if="show"  enctype="multipart/form-data">
<b-row>
    <b-col cols="12"  > 
    <div>
    <b-alert :show="ShowError" dismissible variant="danger">
      
    {{errorMgs}}

    </b-alert>
     </div>
     </b-col>
</b-row>

<b-row align-h="center">
<b-col cols="12" lg="6" xl="6" > 

    
         <!-- input -->
      <b-form-group  :label="$t('first_name')" label-for="input-1" :class="{ 'form-group--error': $v.form.name.$error }">
        <b-form-input  v-model.trim="form.name" @input="setTouched('name')" type="text" required ></b-form-input>
         <b-form-invalid-feedback  v-if="$v.form.name.$error"  :state="$v.form.name.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
       <!-- input -->
      <b-form-group  :label="$t('last_name')" label-for="input-1" :class="{ 'form-group--error': $v.form.last_name.$error }">
        <b-form-input   v-model.trim="form.last_name" @input="setTouched('last_name')" type="text" required ></b-form-input>
         <b-form-invalid-feedback v-if="$v.form.last_name.$error" :state="$v.form.last_name.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
        <!-- input -->
      <b-form-group :label="$t('Email')" label-for="input-1" :class="{ 'form-group--error': $v.form.email.$error }">
        <b-form-input   v-model.trim="form.email" @input="setTouched('email')" type="email" required ></b-form-input>
         <b-form-invalid-feedback v-if="$v.form.email.$error" :state="$v.form.email.required"> {{$t('required_form')}} </b-form-invalid-feedback>
         <b-form-invalid-feedback v-if="$v.form.email.$error" :state="$v.form.email.email"> {{$t('email_form')}} </b-form-invalid-feedback>
         <div v-if="errorsBack.email" >
            <b-form-invalid-feedback v-for="error in errorsBack.email" :key="error"   :state="false"> {{error}}</b-form-invalid-feedback>
         </div>
      </b-form-group>
       <!-- input -->
      <b-form-group  :label="$t('phone')" label-for="input-1" :class="{ 'form-group--error': $v.form.phone.$error }">
        <b-form-input  v-model.trim="form.phone" @input="setTouched('phone')" type="text"  ></b-form-input>
         <b-form-invalid-feedback  v-if="$v.form.phone.$error"  :state="$v.form.phone.numeric"> {{$t('phone_form')}} </b-form-invalid-feedback>
           <div v-if="errorsBack.phone" >
            <b-form-invalid-feedback v-for="error in errorsBack.phone" :key="error"   :state="false"> {{error}}</b-form-invalid-feedback>
         </div>
      </b-form-group>
     
 <!-- input -->
      <b-form-group  :label="$t('role')" label-for="input-3" :class="{ 'form-group--error': $v.form.role.$error }">
        <b-form-select  v-model="form.role" :options="roles" @input="setTouched('role')" required ></b-form-select>
        <b-form-invalid-feedback v-if="$v.form.role.$error" :state="$v.form.role.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
      
    
   
      
   </b-col>

   <b-col cols="12" lg="6" xl="6" > 
          <!-- input -->
        <b-form-group  :label="$t('Image')" label-for="input-2">
         <b-form-file v-model="form.image"  :placeholder="$t('uploadImage')" accept="image/jpeg, image/png, image/gif"
          drop-placeholder="Drop file here..."
         ></b-form-file>
           <div v-if="errorsBack.image" >
            <b-form-invalid-feedback v-for="error in errorsBack.image" :key="error"   :state="false"> {{error}}</b-form-invalid-feedback>
         </div>
          </b-form-group>
        <!-- input -->
      <b-form-group  :label="$t('Password')" label-for="input-2" :class="{ 'form-group--error': $v.form.password.$error }">
        <b-form-input  v-model="form.password" type="password" @input="setTouched('password')" required ></b-form-input>
        <b-form-invalid-feedback v-if="$v.form.password.$error" :state="$v.form.password.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
       <!-- input -->
      <b-form-group  :label="$t('password_confirmation')" label-for="input-2" :class="{ 'form-group--error': $v.form.confirm_password.$error }">
        <b-form-input  v-model="form.confirm_password" type="password" @input="setTouched('confirm_password')" required ></b-form-input>
        <b-form-invalid-feedback v-if="$v.form.confirm_password.$error" :state="$v.form.confirm_password.required"> {{$t('required_form')}} </b-form-invalid-feedback>
        <b-form-invalid-feedback v-if="$v.form.confirm_password.$error" :state="$v.form.confirm_password.sameAsPassword"> {{$t('passwordconfirmation')}} </b-form-invalid-feedback>
      </b-form-group>

      <!-- input -->
      <b-form-group  :label="$t('Status')" label-for="input-3" :class="{ 'form-group--error': $v.form.status.$error }">
        <b-form-select  v-model="form.status" :options="statuses" @input="setTouched('status')" required ></b-form-select>
        <b-form-invalid-feedback v-if="$v.form.status.$error" :state="$v.form.status.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
   </b-col>
   </b-row>
<b-row>
    <b-col cols="12"  >
    <b-button type="submit" variant="info"><b-spinner v-if="isSendingForm" small></b-spinner>  {{$t('Save')}}</b-button>
     </b-col>
</b-row>






    </b-form>
     </b-card>
</b-container>

</div>




</template>

<script>
import SubHeader from '@/components/layout/SubHeader.vue'
import axios from 'axios'
import { required, email,sameAs,numeric } from 'vuelidate/lib/validators'
import DoneAlert from '@/components/alerts/DoneAlert.vue'
export default {
        data() {
      return {
        form: {
            name: null,
            last_name: null,
            email: null,
            phone:null,
            password: null,
            confirm_password:null,
            status:null,
            image:null,
            role:null,
            lang_id:this.$i18n.locale,
           

        },
        statuses: [ 'active', 'deactivated', 'waiting review'],
        roles:[],
        errorsBack:{},
        errorMgs:'',
        ShowError: false,
        show: true,
        ShowDoneAlert:false,
        isSendingForm:false
      }
    },
    components:{
        SubHeader, DoneAlert
        
    },
     validations: {
      form:{
        phone:{numeric},
        role: {  required },
        status: {  required },
        name: {  required },
        last_name: { required },
        email: { required,email},
        password: {required},
        confirm_password: { required, sameAsPassword: sameAs('password') }
    }
  },
  /***************** */
      methods: {

     setTouched(theModel) {
       if(theModel == 'phone' || theModel == 'all' ){this.$v.form.phone.$touch()}
       if(theModel == 'role' || theModel == 'all' ){this.$v.form.role.$touch()}
       if(theModel == 'status' || theModel == 'all' ){this.$v.form.status.$touch()}
       if(theModel == 'name' || theModel == 'all' ){this.$v.form.name.$touch()}
       if(theModel == 'last_name' || theModel == 'all' ){this.$v.form.last_name.$touch()}
      if(theModel == 'email' || theModel == 'all' ){this.$v.form.email.$touch()}
      if(theModel == 'password' || theModel == 'all'){this.$v.form.password.$touch()}
      if(theModel == 'confirm_password' || theModel == 'all'){this.$v.form.confirm_password.$touch()}
      
    },
        fixingOption(json){
          var array=[];
          for (let index = 0; index < json.length; index++) {
            array[index] ={"text":this.$t(json[index].name),"value":json[index].name} ;
            
          }
           return array;
        },
      getCreateData(){
      axios.get(this.$store.state.backendUrl+'api/user/create', {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
                if(response.Error){this.ShowError=true;this.errorMgs = this.$t('error_login');}
                else{
                 this.roles = this.fixingOption (response.data.success.roles);
                 console.log(this.roles);
                }
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } , 
      onSubmit(event) {
        event.preventDefault()
        if (!this.$v.$invalid) {

       this.isSendingForm = true;
        //alert(JSON.stringify(this.form))
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
              if(value != null){
             formData.append(key,value)
              console.log(key, value);
              }
          }
      
        axios.post(this.$store.state.backendUrl+'api/user',formData, {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
               
                  console.log(response.data.success);
                  this.isSendingForm = false;
                  this.ShowDoneAlert = true;
                  this.ResetForm();
                  setTimeout(()=>{     
                  this.$router.push({ name: 'User_edit', params: { id: response.data.success.user.id} })
                  }, 3000);
            })
            .catch( (error) => {
                console.log(error.response);
                this.ShowError=true;
                this.errorMgs = error;
                this.isSendingForm = false;
                 if(error){
                    this.errorsBack = error.response.data.errors;
                     console.log(error.response.data.errors);
                  }
            });
           }
      },
     
      ResetForm() {
        
        // Reset our form values
       
        this.form.name = null,
        this.form.last_name = null,
         this.form.email = null,
        this.form.phone = null,
        this.form.password = null,
        this.form.status = null,
        this.form.image = null,
        this.form.role = null,
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    },
    /********************* */
    mounted() {
      this.getCreateData();
      //this.form.lang_id = this.$i18n.locale;
    }
    
}
</script>